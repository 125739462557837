import React, { useState, useEffect } from 'react';

const AdsModal = () => {
  const [showModal, setShowModal] = useState(false);

  const onClose = () => {
    setShowModal(false);
    setTimeout(() => {}, 0);
  }

  useEffect(() => {
    const showModalTimeout = setTimeout(() => {
      setShowModal(true);
    }, 15000);

    return () => clearTimeout(showModalTimeout);
  }, [])

  return (
    <div className={`ads-modal ${showModal ? 'show' : 'hide'}`}>
      <div className="ads-modal-content">
        <img alt="" src="/assets/img/sell.png"/>
        <span id="ads-modal-close" className="ads-close" onClick={onClose}>&times;</span>
        <div className="ads-text">
          <h3>現在 聯繫派星資訊</h3>
          <p>
            <b>喜歡看到的網站模板嗎?</b>
            <br/>
            除了現在看到的樣式，我們還有很多不同種類的網站樣式，同樣也可以依照您的需求訂製製作不同UI頁面，馬上聯繫我們，了解更多吧!
          </p>
          <div className="ads-button-content">
            <span onClick={onClose} id="ads-modal-fail">考慮一下</span>
            <a
              id="ads-modal-success"
              href="https://patrick-tech.com/?utm_source=e-commerce&utm_medium=website&utm_campaign=demo_site_click&utm_id=demo-site-7&utm_term=e-commerce-modal"
              target="_blank"
              rel="noreferrer"
              onClick={() => setShowModal(false)}
            >馬上聯繫</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdsModal;